import { Link } from "react-router-dom";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  archiveProject,
  selectAllDatasetsInProject,
  updateDataset,
  getAllDatasets,
} from "./datasetSlice";
import { handleError } from "../errors/errorUtils";
import { throwIfError } from "../../utils/error";
import { useEffect } from "react";

export default function DatasetList({ projectId }: { projectId: string }) {
  const dispatch = useAppDispatch();
  const datasets = useAppSelector((state) =>
    selectAllDatasetsInProject(state, projectId)
  );
  useEffect(() => {
    dispatch(getAllDatasets({ projectId }));
  }, []);
  async function handleArchiveClick(id: string) {
    try {
      const confirm = window.confirm(
        "Are you sure you want to archive this dataset?"
      );
      if (!confirm) return;
      await throwIfError(dispatch(archiveProject(id)));
    } catch (err) {
      handleError(err);
    }
  }
  async function handleRenameClick(id: string) {
    try {
      const name = window.prompt(
        "What would you like to rename this dataset to?"
      );
      await throwIfError(dispatch(updateDataset({ id, name })));
    } catch (err) {
      handleError(err);
    }
  }
  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Datasets
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Link to="/datasets/create">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {datasets.map((dataset: any) => (
                  <tr key={dataset.id}>
                    <td className="whitespace-pre-wrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 font-bold">
                      <Link to={`/datasets/${dataset.id}`}>
                        {dataset.name || "Untitled"}
                      </Link>
                    </td>
                    <td className="whitespace-pre-wrap pr-6 text-sm text-gray-900 space-x-2 text-right">
                      <button
                        className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded"
                        onClick={() => handleArchiveClick(dataset.id)}
                      >
                        <TrashIcon className="text-gray-600" />
                      </button>
                      <button
                        className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded"
                        onClick={() => handleRenameClick(dataset.id)}
                      >
                        <PencilIcon className="text-gray-600" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
