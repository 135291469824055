"use client";

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createRow,
  insertRowIntoViewStart,
  selectRowById,
  updateRow,
} from "./rowSlice";
import { throwIfError } from "../../../utils/error";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function RowEditorModal({
  open,
  setOpen,
  editedRowId,
  datasetId,
  onRowCreate,
  onRowDelete,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  editedRowId: string | null;
  datasetId: string;
  onRowCreate?: (data: any) => void;
  onRowDelete?: (id: string) => Promise<void> | void;
}) {
  const row = useAppSelector((state) =>
    editedRowId ? selectRowById(state, editedRowId) : null
  );
  const dispatch = useAppDispatch();
  const [inputsValue, setInputs] = useState("{}");
  const [outputsValue, setOutputs] = useState("{}");
  const [inputsError, setInputsError] = useState("");
  const [outputsError, setOutputsError] = useState("");

  useEffect(() => {
    if (row) {
      setInputs(JSON.stringify(row.inputs));
      setOutputs(JSON.stringify(row.outputs));
    } else {
      setInputs("{}");
      setOutputs("{}");
    }
  }, [row?.id]);

  function handleInputsChange(value: string) {
    setInputs(value);
    try {
      JSON.parse(value);
      setInputsError("");
    } catch (err) {
      setInputsError("Invalid JSON.");
    }
  }
  function handleOutputsChange(value: string) {
    setOutputs(value);
    try {
      JSON.parse(value);
      setOutputsError("");
    } catch (err) {
      setOutputsError("Invalid JSON.");
    }
  }

  async function handleSave() {
    try {
      const inputsObj = JSON.parse(inputsValue);
      const outputsObj = JSON.parse(outputsValue);
      if (editedRowId) {
        await throwIfError(
          dispatch(
            updateRow({
              id: editedRowId,
              inputs: inputsObj,
              outputs: outputsObj,
            })
          )
        );
      } else {
        const result = await throwIfError(
          dispatch(
            createRow({ inputs: inputsObj, outputs: outputsObj, datasetId })
          )
        );
        if (onRowCreate) {
          onRowCreate(result.payload);
        }
      }

      setOpen(false);
    } catch (err) {
      console.error(err);
      setInputsError("Invalid JSON.");
      setOutputsError("Invalid JSON.");
    }
  }

  async function handleRowDelete() {
    if (!onRowDelete || !row) {
      console.error("onRowDelete or row is not defined.");
      return;
    }
    await onRowDelete(row.id);
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="h-screen w-screen p-8">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in w-full h-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="flex flex-col h-full">
                <div className="border-b px-4 py-3">
                  <DialogTitle
                    as="h3"
                    className="text-base font-medium leading-6 text-gray-900"
                  >
                    {editedRowId ? "Edit" : "Add"} Row
                  </DialogTitle>
                </div>
                <div className="px-4 py-4 flex-1 flex flex-row space-x-4">
                  <div className="flex-1">
                    <textarea
                      id="inputs"
                      name="inputs"
                      rows={4}
                      value={inputsValue}
                      className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => handleInputsChange(e.target.value)}
                    />
                    {inputsError && (
                      <div className="text-red-500">{inputsError}</div>
                    )}
                  </div>
                  <div className="flex-1">
                    <textarea
                      id="outputs"
                      name="outputs"
                      rows={4}
                      className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={outputsValue}
                      onChange={(e) => handleOutputsChange(e.target.value)}
                    />
                    {outputsError && (
                      <div className="text-red-500">{outputsError}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row px-4">
                  <div className="flex-1">
                    {row ? (
                      <button
                        type="button"
                        className="px-3 py-3 hover:bg-gray-100 rounded"
                        onClick={handleRowDelete}
                      >
                        <TrashIcon className="w-4 h-4 text-red-700" />
                      </button>
                    ) : null}
                  </div>
                  <button
                    type="button"
                    onClick={() => handleSave()}
                    className="my-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
