import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useCallback, useEffect } from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { debounce } from "lodash";
import { getAllRows, selectRowViewByFilter } from "./rowSlice";

export default function RowList({
  datasetId,
  onRowClick,
}: {
  datasetId: string;
  onRowClick: (rowId: string) => void;
}) {
  const dispatch = useAppDispatch();
  const filter = { datasetId };
  const view = useAppSelector((state) => selectRowViewByFilter(state, filter));
  useEffect(() => {
    dispatch(getAllRows(filter));
  }, [datasetId]);

  const debouncedGetAllRows = useCallback(
    debounce(({ skip, limit }: { skip: number; limit: number }) => {
      dispatch(getAllRows({ ...filter, skip, limit }));
    }, 250),
    [dispatch, filter]
  );

  const Row = ({ index, style }: { index: number; style: any }) => {
    if (!view) return null;
    const row = view.itemsByIndex[index];
    if (!row) return null;
    return (
      <div
        key={row.id}
        className="border-b flex flex-row hover:bg-gray-50 cursor-default hover:cursor-default"
        style={style}
        onClick={() => onRowClick(row.id)}
      >
        <div className="whitespace-pre-wrap px-2 text-sm text-gray-500 space-x-2 text-right flex flex-col justify-center items-center w-12 border-r">
          {index + 1}
        </div>
        <div className="flex-1 flex flex-row">
          <div className="whitespace-pre-wrap pl-2 pr-3 text-xs text-gray-900 sm:pl-4 w-1/2 flex flex-row items-center justify-start border-r">
            {JSON.stringify(row.inputs)}
          </div>
          <div className="whitespace-pre-wrap pl-2 pr-4 text-xs text-gray-900 sm:pl-4 space-x-2 w-1/2 flex flex-row items-center justify-start">
            {JSON.stringify(row.outputs)}
          </div>
        </div>
      </div>
    );
  };
  if (!view) return null;
  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={view.totalCount}
          itemSize={38}
          width={width}
          onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
            debouncedGetAllRows({
              skip: Math.max(visibleStartIndex - 50, 0),
              limit: visibleStopIndex - visibleStartIndex + 50,
            });
          }}
          overscanCount={20}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
