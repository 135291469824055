import axios from "axios";

export async function fetchAll(params: {
  workspaceId: string;
}): Promise<{ results: Array<any> }> {
  const { data } = await axios.get("/api/datasets", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/datasets/${id}`);
  return data;
}

export async function create(body: any): Promise<any> {
  const { data } = await axios.post(`/api/datasets`, body);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/datasets/${id}`, body);
  return data;
}

export async function archive(id: string): Promise<any> {
  const { data } = await axios.post(`/api/datasets/${id}/archive`);
  return data;
}
