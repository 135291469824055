import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import DatasetList from "../features/datasets/DatasetList";
import PaddedContainer from "../components/containers/PaddedContainer";

export default function DatasetListView() {
  const projectId = useAppSelector((state) => state.projects.activeId);
  const navigate = useNavigate();
  if (!projectId) {
    navigate("/projects");
    return <div></div>;
  }
  return (
    <PaddedContainer className="pt-24">
      <DatasetList projectId={projectId} />
    </PaddedContainer>
  );
}
