import { useEffect, useState } from "react";
import Row from "./rows/Row";
import { uuidv4 } from "../../../../shared/utils/uuid";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addRow,
  createRow,
  deleteRow,
  getAllRows,
  insertRowIntoViewStart,
  removeRow,
  removeRowFromView,
  selectDraftRowsByDatasetId,
  selectRowViewByFilter,
  updateRow,
} from "./rows/rowSlice";
import { handleError } from "../errors/errorUtils";
import RowEditorModal from "./rows/RowEditorModal";
import RowList from "./rows/RowList";

export default function Dataset({ dataset }: { dataset: any }) {
  const filter = { datasetId: dataset.id };
  const [showRowEditor, setShowRowEditor] = useState(false);
  const view = useAppSelector((state) => selectRowViewByFilter(state, filter));
  const draftRows = useAppSelector((state) =>
    selectDraftRowsByDatasetId(state, dataset.id)
  );
  const rows: any[] = view ? Object.values(view.itemsByIndex) : [];
  const [editedRowId, setEditedRowId] = useState<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllRows({ datasetId: dataset.id }));
  }, []);

  async function handleRowCreate(row: any) {
    try {
      dispatch(insertRowIntoViewStart({ id: row.id, filter }));
    } catch (err) {
      handleError(err);
    }
  }

  function handleDeleteRow(id: string) {
    const confirm = window.confirm("Are you sure you want to delete this row?");
    if (!confirm) return;
    dispatch(removeRowFromView({ id, filter }));
    dispatch(deleteRow(id));
  }
  return (
    <>
      <div className="flex flex-row border-b">
        <h1 className="py-2 px-4 text-lg flex-1">
          {dataset.name || "Untitled Dataset"}
        </h1>
        <div className="px-12 flex flex-row justify-center items-center">
          <button
            className="hover:bg-gray-100  text-xs py-2 px-3 rounded"
            onClick={() => {
              setShowRowEditor(true);
              setEditedRowId(null);
            }}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="flex-1">
        <RowList
          datasetId={dataset.id}
          onRowClick={(rowId: string) => {
            setShowRowEditor(true);
            setEditedRowId(rowId);
          }}
        />
      </div>
      <RowEditorModal
        open={showRowEditor}
        setOpen={setShowRowEditor}
        editedRowId={editedRowId}
        datasetId={dataset.id}
        onRowCreate={handleRowCreate}
        onRowDelete={handleDeleteRow}
      />
    </>
  );
}
