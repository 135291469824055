import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  toArray,
} from "../../utils/normalizedState";
import { fetchAll, fetchOne, create, update, archive } from "./datasetAPI";

const initialState: NormalizedState<any> = {
  ...initNormalizedState(),
};

export const getAllDatasets = createAsyncThunk(
  "datasets/getAll",
  async (params: any, { getState }) => {
    const state = getState() as RootState;
    const { results } = await fetchAll({
      projectId: state.projects.activeId,
      ...params,
    });
    return results;
  }
);

export const getDataset = createAsyncThunk(
  "datasets/getOne",
  async (id: string) => {
    const response = await fetchOne(id);
    return response;
  }
);
export const createDataset = createAsyncThunk(
  "datasets/create",
  async (body: any, { getState }) => {
    const state = getState() as RootState;
    console.log(state.projects.activeId);
    const response = await create({
      projectId: state.projects.activeId,
      ...body,
    });
    return response;
  }
);

export const updateDataset = createAsyncThunk(
  "datasets/update",
  async ({ id, ...body }: any) => {
    const response = await update(id, body);
    return response;
  }
);

export const archiveProject = createAsyncThunk(
  "datasets/archive",
  async ({ id }: any) => {
    const response = await archive(id);
    return response;
  }
);

export const datasetSlice = createSlice({
  name: "datasets",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllDatasets.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, action.payload);
        }
      )
      .addCase(
        getDataset.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        createDataset.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        updateDataset.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      );
  },
});

export const selectAllDatasetsInProject = createSelector(
  (state: RootState) => state.datasets,
  (state: RootState) => state.projects.activeId,
  (_: RootState, datasetId: string) => datasetId,
  (normalized, projectId) =>
    toArray(normalized).filter((item: any) => item.projectId === projectId)
);

export const selectDatasetById = (state: RootState, id: string) =>
  (state.datasets as any).byId[id];

export default datasetSlice.reducer;
