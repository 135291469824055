import axios from "axios";

export async function fetchAll(params: {
  workspaceId: string;
}): Promise<{ results: Array<any>; totalCount: number }> {
  const { data } = await axios.get("/api/rows", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/rows/${id}`);
  return data;
}

export async function create(body: any): Promise<any> {
  const { data } = await axios.post(`/api/rows`, body);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/rows/${id}`, body);
  return data;
}

export async function destroy(id: string): Promise<any> {
  const { data } = await axios.delete(`/api/rows/${id}`);
  return data;
}
